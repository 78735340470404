<template>
  <div>
    <h1>Test of &lt;MyReactThing&gt;</h1>
    <h3 class="mb-3">
      I'm pretty sure this won't work...
    </h3>
    <MyReactThing />
  </div>
</template>

<script>
// @ is an alias to /src
import MyReactThing from '@/components/react-thing.js';
export default {
  name: 'React',
  components: {
    MyReactThing
  }
};
</script>
