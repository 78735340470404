var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", [_vm._v("Test of <MyReactThing>")]),
      _c("h3", { staticClass: "mb-3" }, [
        _vm._v(" I'm pretty sure this won't work... ")
      ]),
      _c("MyReactThing")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }